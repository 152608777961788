/* eslint-disable no-async-promise-executor */
import axios from "axios";
import { makeSecureRestApi } from "../api/xmmAxios";
import manufacturerCodes from "../features/page-wrapper/constants/manufacturer-codes.constants";
import isEmpty from "lodash/isEmpty";
import { YES } from "../features/repair-order/constants/adjustment.constant";

/**
This function returns manufacturer codes based on the dealer's properties and dealer code. 
It handles different cases for retrieving the manufacturer codes:

CDK case: Reads manufacturer codes from the catalog API behind the Exeter dealer property SPI_SQS_ENABLED.
DTDMS case: Reads manufacturer codes from a local constant file.
DMS+ case: Reads manufacturer codes from the Exeter dealer property MANUFACTURER_MAKE_CODE_LIST if configured.
 * @param {Object} dealerProperties An object containing the properties of the dealer
 * @param {String} dealerCode 
 * @returns 
 */
const readManufacturerCodesByDealer = async (dealerProperties, dealerCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      // DTDMS,DMS+ case: read MFcodes from constant file by default
      let mfCodes = manufacturerCodes || [];
      const isDMSPlus = dealerProperties?.ENABLE_CSR_APP === YES;
      const isSpiSqsEnabled = dealerProperties?.SPI_SQS_ENABLED === YES;
      // CDK case: fetch CDK MF codes using catalog API when dealer property as SPI_SQS_ENABLED == "Y"
      if (isSpiSqsEnabled) {
        mfCodes = await getCDKDealerMFcodes(dealerCode);
      } else if (isDMSPlus) {
        mfCodes = parseDealerMakesPropertyForDmsplus(
          dealerProperties?.MANUFACTURER_MAKE_CODE_LIST
        );
      }
      console.log(`Dealer MF codes for ${dealerCode}:`, mfCodes);
      resolve(mfCodes);
    } catch (error) {
      console.log("Unable to fetch dealer MF codes.", error);
      reject(error);
    }
  });
};
// DMS+ case: Parse String of makesCodes read from exeter dealer prop
const parseDealerMakesPropertyForDmsplus = strMakeCodes => {
  let dmsMFcodes = manufacturerCodes || [];
  try {
    if (strMakeCodes && strMakeCodes.length) {
      dmsMFcodes = strMakeCodes.split("|").map(item => {
        const values = item.split(",");
        return {
          make: values[0],
          code: values[1],
          description: values[2]
        };
      });
    }
    return dmsMFcodes;
  } catch (err) {
    console.error(`parseDealerMakesPropertyForDmsplus error: ${err}`);
    return dmsMFcodes;
  }
};
// API wrapper for mf codes
const getCDKDealerMFcodes = dealerCode => {
  const restUrl = `/dealer/${dealerCode}/cdkDealerMfcodes`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {}
      },
      response => {
        let extractedData = [];
        if (response) {
          const fieldsToExtract = ["make", "dealerMfcode", "cdkDefaultMfcode"];
          extractedData = extractFields(response, fieldsToExtract);
        }
        resolve(extractedData);
      },
      error => {
        console.error(error);
        reject([]);
      },
      "Unable to retrieve cdk dealer mfcodes."
    );
  });
};

// Function to extract specified fields from an array of JSON objects
export function extractFields(dataList, fields) {
  return dataList.map(item => {
    const extracted = {};
    fields.forEach(field => {
      if (item.hasOwnProperty(field)) {
        extracted[field] = item[field];
        extracted["code"] = item.dealerMfcode || "";
        extracted["description"] = item.make || "";
      }
    });
    return extracted;
  });
}

// Placeholder: Function to read manufacturer code for given make
const getCDKmfcodeByMake = (mfcodeList, make) => {
  let code = "OT";
  if (!isEmpty(mfcodeList) && make) {
    const matches = mfcodeList.filter(m => {
      return m.make.toUpperCase() === make.toUpperCase();
    });
    code = matches.length > 0 ? matches[0].code : "OT";
  }
  console.log("getCDKmfcodeByMake", code);
  return code;
};

// placeholder test: Function to fetch CDK manufacturer codes from the catalog API
const getCDKDealerMFcodesMockAPI = async dealerCode => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get("/data/mf-codes.data.json");
      console.log("getCDKDealerMFcodesMock", dealerCode);
      const fieldsToExtract = ["make", "dealerMfcode", "cdkDefaultMfcode"];
      const extractedData = extractFields(response?.data, fieldsToExtract);
      setTimeout(() => {
        resolve(extractedData || []);
      }, 1000);
    } catch (error) {
      reject(error);
    }
  });
};

export default {
  readManufacturerCodesByDealer,
  getCDKDealerMFcodes,
  getCDKDealerMFcodesMockAPI,
  extractFields,
  getCDKmfcodeByMake
};
