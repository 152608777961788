import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";
import { priceSourceLabels } from "../constants/pages.constants";
import { CatalogSources } from "../features/page-wrapper/constants/page-wrapper.constants";
import { generatePartId } from "./helper.util";
import { getManufacturerCode } from "../features/page-wrapper/utils/data-util";

// Special case - Util used to read catalog part.priceSource and transform value into Standard values {MSRP, DMS}
const refinePriceSource = priceSource => {
  const formattedPriceSource = !isEmpty(priceSource)
    ? priceSource.toLowerCase(priceSource)
    : priceSourceLabels.MSRP.toLowerCase();
  const msrpRegEx = /msrp/i;
  const dmsRegEx = /dms/i;
  const manualRegEx = /manual/i;
  if (msrpRegEx.test(formattedPriceSource)) {
    return priceSourceLabels.MSRP;
  }
  if (dmsRegEx.test(formattedPriceSource)) {
    return priceSourceLabels.DMS;
  }
  if (manualRegEx.test(formattedPriceSource)) {
    return priceSourceLabels.MANUAL;
  }
  return priceSourceLabels.MSRP;
};

// Util called For Dealer Publish, Menu service cases: read object[rawOperationDetails] and transform parts from laborApps[0]
export const transformParts = (parts, catalogSource = null) => {
  console.log(
    "[common parts util] transformParts called",
    catalogSource,
    parts
  );
  const rawParts = cloneDeep(parts);
  rawParts.forEach(part => {
    // IMPORTANT - Never override Set Default MSRP to priceSource for parts coming from catalog API response (menu, dealer pub, global repair)
    part.priceSource = refinePriceSource(part.priceSource);
    part.unitPrice = get(part, "unitPrice", 0);
    // @note: dealer Published and menu services should always be pre-selected
    if (catalogSource === CatalogSources.MENU) {
      part.selected = get(part, "selected", true);
    }
    // derived fields
    part.partPriceSource = get(part, "partPriceSource", null);
    part.dmsPrice = null;
    part.dmsPending = false;
    part.recordType = "RECOMMENDED";
  });
  console.log("[common parts util] transformParts output", rawParts);
  return rawParts;
};

// common util to show/hide loading mask at parts grid cells
export function resetDmsPending(parts, dmsPending) {
  parts.forEach(p => {
    if (p.oemPartNumber || p.partNumber) {
      p.dmsPending = dmsPending;
    }
  });
}

/**
 * It returns the Y/N for isCorePart.
 * Notes that in the future, it will need to be return true/false as boolean.
 *
 * @param {*} isCorePart
 * @returns Y/N or in the future true/false
 */
function getIsCorePart(isCorePart) {
  return isCorePart === "Y" || isCorePart === true ? true : false;
}

/**
 * so called in EditService Module, edit service context
 * IMPORTANT - Util reads fields from converted API response (pricingAndInventory)
 * Method prepares Parts[] with UI derived fields used for selected parts grid binding
 * @param {*} dmsPartsList - DMS API response (converted)
 * @param {*} serviceParts - parts from service
 * @param {*} catalogSource - to detect service type used for MENU case
 * @param {*} hasDealerTire - TRUE - special check for Dealer Tire service, to update only quantityAvailable under Part
 * @param {*} isModifyFromSummary - flag param defaulted to false which will only come as true if the method is called as part of the modify parts from summary flow
 * @return {*} Parts with DMS values
 */
export const transformPartsWithDMSParts = (
  dmsPartsList,
  servicePartsInput,
  catalogSource,
  hasDealerTire = false,
  isModifyFromSummary = false
) => {
  let serviceParts = [...servicePartsInput];
  if (!isEmpty(dmsPartsList)) {
    const dmsPartsMap = dmsPartsList
      .filter(p => p.dmsSource)
      .reduce((map, obj) => {
        map[obj.partNumber] = obj;
        return map;
      }, {});
    serviceParts = attachCorePartsIfAny(serviceParts, dmsPartsList);

    serviceParts.forEach(part => {
      if (!isNull(part.oemPartNumber) && !(part?.partPrice < 0)) {
        const dmsPart = dmsPartsMap[part.oemPartNumber];
        if (dmsPart) {
          const dmsPriceVal = get(dmsPart, "dmsPrice", null);
          part.partPriceSource = getPartPriceSource(part, dmsPart);
          // Case1: All services - DMS API fields used to update {unitPrice, partName, quantityAvailable,dmsPrice}
          // Case2: Dealer Tire - Only {quantityAvailable} value used from DMS API; rest fields will be ignored
          if (!hasDealerTire) {
            part.dmsPrice = dmsPriceVal;
            // Update unitPrice with dmsPrice if it is dmsPart; else use from original part's unit price
            part.unitPrice = !dmsPriceVal ? part.unitPrice : dmsPriceVal;
            part.partName = isEmpty(part.partName)
              ? dmsPart.partDescription
              : part.partName;
          }
          part.quantityAvailable = dmsPart.quantityAvailable;
          part.costPrice = dmsPart.costPrice ? dmsPart.costPrice : null;
          part.unitCost =
            dmsPart?.unitCost && dmsPart?.unitCostOverride
              ? dmsPart.unitCost
              : dmsPart.costPrice ?? null;
          part.isCorePart = getIsCorePart(dmsPart.isCorePart);
          part.bin = dmsPart.bin ? dmsPart.bin : null;
          part.type = dmsPart.type ? dmsPart.type : null;
          part.location = dmsPart.location ? dmsPart.location : null;
          part.shelf = dmsPart.shelf ? dmsPart.shelf : null;
        } else {
          // For Non-DMS parts - set dmsPrice=null, partPriceSource as returned from API
          part.dmsPrice = null;
          part.partPriceSource = getPartPriceSource(part);
        }
        // priceSource is what saved to DB
        part.priceSource = part.partPriceSource;
        part.dmsPending = false;
        part.recordType = "RECOMMENDED";
        // isModifyFromSummary - passed as true- prevent auto-select parts coming from menu API
        if (catalogSource === CatalogSources.MENU && !isModifyFromSummary) {
          part.selected = true;
        }
      }
    });
    resetDmsPending(serviceParts, false);
    console.log("common-parts util > transformPartsWithDMSParts", serviceParts);
  }
  return serviceParts;
};
// Replace this logic using partPriceSource
// NOTE: UI derived field "partPriceSource" to cover below cases to hold DMS api value and when user modifies unitPrice cell
// Case1: partPriceSource = null, default value; when API parts are read first time
// Case2: partPriceSource = MANUAL, when user modified unit price in grid; once Manual always Manual even if DMS price exist
// Case3: partPriceSource = DMS, when DMS API returns dmsPrice; mark is as "DMS" until if user modify dms price/msrp price from UI
// Case4: partPriceSource = MANUAL, when dealer tire service with parts; mark always as MANUAL even if DMS API returns dms price

function getPartPriceSource(part, dmsPart = null) {
  let partPriceSource = get(part, "partPriceSource", priceSourceLabels.MSRP);
  if (!isEmpty(dmsPart)) {
    const dmsPriceVal = get(dmsPart, "dmsPrice", null);
    if (partPriceSource !== priceSourceLabels.MANUAL) {
      partPriceSource = !isNull(dmsPriceVal)
        ? priceSourceLabels.DMS
        : priceSourceLabels.MSRP;
    }
  } else {
    partPriceSource =
      !isEmpty(partPriceSource) && partPriceSource !== priceSourceLabels.MANUAL
        ? priceSourceLabels.MSRP
        : priceSourceLabels.MANUAL;
  }
  return partPriceSource;
}
// util used for csr-part logic
function attachCorePartsIfAny(serviceParts, dmsPartsList) {
  const servicePartsMap = serviceParts
    .filter(p => p.oemPartNumber)
    .reduce((map, obj) => {
      map[obj.oemPartNumber] = obj;
      return map;
    }, {});
  dmsPartsList.forEach(dmsPart => {
    const part = servicePartsMap[dmsPart.partNumber];
    const parentPart = servicePartsMap[dmsPart.manufacturerPartNoParent];
    if (!part && parentPart) {
      const rowId = generatePartId();
      const extPartId = rowId;
      // check if we need to pass this sequence as partId
      const partId = rowId.toString();
      const corePart = {
        ...parentPart,
        ...dmsPart,
        oemPartNumber: dmsPart.partNumber,
        extPartId,
        rowId,
        partId
      };
      serviceParts.push(corePart);
    }
  });
  return serviceParts;
}

// IMPORTANT: read and convert parts Inventory API response of partsLookup/pricingAndInventory
// Make sure required fields validated and copied from API response
export const convertPricingAndInventoryAPIData = (response, partsParam) => {
  let dmsParts = [];
  const coreParts = [];
  if (!isEmpty(response.data.parts)) {
    let hasCorParts = false;
    let partsMap = {};
    const dmsPartsMap = response.data.parts
      .filter(p => !p.code)
      .reduce((map, obj) => {
        if (!obj.partNumber && obj.manufacturerPartNoParent) {
          obj.partNumber = `${obj.manufacturerPartNoParent}CC`;
          hasCorParts = true;
        }
        map[obj.partNumber] = obj;
        return map;
      }, {});
    console.log(
      "DMS PARTS API/response/payload",
      response.data.parts,
      partsParam
    );
    // if API has dmsParts then only return parts to edit service level, summary service/menu/modify parts cases
    if (!isEmpty(dmsPartsMap)) {
      // Important: Must read API field "manufacturerCode" value and carry in parts record
      partsParam.forEach(p => {
        p.dtDmsPartCode = p?.manufacturerCode || partsParam?.manufacturerCode;
        dmsParts.push(p);
      });
      if (hasCorParts) {
        partsMap = dmsParts.reduce((map, p) => {
          map[p.partNumber] = p;
          return map;
        }, {});
      }

      dmsParts.forEach(p => {
        if (p.partNumber) {
          // Note - UI derived field to show/hide load mask for DMS parts grid cells
          p.dmsPending = false;
          const dmsPart = dmsPartsMap[p.partNumber];
          if (dmsPart) {
            p.quantityAvailable = dmsPart?.quantityAvailable;
            // use partDescription to support paytypes change
            p.partDescription = dmsPart?.partDescription || "";
            // Important: API field "partPrice" value saved in new derived field called "dmsPrice"
            p.dmsPrice = dmsPart?.partPrice || null;
            // Important: This new field "dmsSource" used to identify dmsPart or not in transform methods
            p.dmsSource = priceSourceLabels.DMS;
            p.costPrice = dmsPart.costPrice ? dmsPart.costPrice : null;
            p.isCorePart = getIsCorePart(dmsPart.isCorePart);
            p.bin = dmsPart.bin ? dmsPart.bin : null;
            p.type = dmsPart.type ? dmsPart.type : null;
            p.location = dmsPart.location ? dmsPart.location : null;
            p.shelf = dmsPart.shelf ? dmsPart.shelf : null;
            p.unitCost = dmsPart.costPrice ? dmsPart.costPrice : null;
            p.message = dmsPart.message ? dmsPart.message : "";
            // check core part
            if (hasCorParts) {
              const corePartNumber = `${p.partNumber}CC`;
              const corePart = dmsPartsMap[corePartNumber];
              if (corePart && !partsMap[corePartNumber]) {
                const dynamicPart = {
                  ...p,
                  ...corePart,
                  unitPrice: corePart.costPrice,
                  dmsPrice: corePart?.partPrice
                };
                coreParts.push(dynamicPart);
              }
            }
          } else {
            // @note - For Non-DMS parts - set null to derived fields
            p.dmsPrice = null;
            p.dmsSource = null;
            p.dmsPending = false;
          }
        } // if ends partNumber
      });
    }
    dmsParts = dmsParts.concat(coreParts);
    resetDmsPending(dmsParts, false);
  } else {
    // when API returns empty dms parts
    resetDmsPending(dmsParts, false);
  }
  console.log("convertPricingAndInventoryAPIData", dmsParts);
  return dmsParts;
};

// common util called for editing quote service, modify link flow from summary page
export const buildPart = (savedPart, lookupPart) => {
  const {
    approver,
    extPartId,
    description: partName,
    adjustedQuantity: quantity,
    unitPrice,
    priceSource
  } = savedPart;

  const partId = isEmpty(lookupPart)
    ? !extPartId
      ? generatePartId().toString()
      : extPartId.toString()
    : lookupPart.partId;
  return {
    ...(!isEmpty(lookupPart) ? { ...lookupPart, ...savedPart } : savedPart),
    approver,
    partId,
    rowId: Number(partId),
    extPartId: !extPartId ? Number(partId) : extPartId,
    quoteServicePartId: savedPart?.quoteServicePartId || null,
    partName,
    quantity,
    unitPrice,
    priceSource,
    ...(priceSource === "MANUAL" && {
      partPriceSource: priceSource
    })
  };
};

/** placeholder
 * This function updates the dealer manufacturer code (mfcode) for each part record in the laborApps object provided in the responseData.
 * It ensures that each part record is associated with the correct manufacturer code based on the specified make.
 * @param {Object} responseData The response data containing service details, including part records.
 * @param {String} make The vehicle make used to find manufacturer code.
 * @returns The updated response data with the dealer manufacturer code set for each part record.
 */
export function refineOperationDetaisApiWithMFcode(responseData, make) {
  if (!isEmpty(responseData)) {
    const mfcode = getManufacturerCode(make);
    const { laborApps } = responseData;
    if (!isEmpty(laborApps)) {
      laborApps.forEach(op => {
        if (!isEmpty(op.parts)) {
          refinePartsWithMFcode(op.parts, mfcode);
        }
      });
    }
  }
}

/** placeholder
 * This function updates the dealer manufacturer code (mfcode) for each part record
 * @param {Array} parts
 * @param {String} mfcode
 * @returns The updated response data with the dealer manufacturer code set for each part record.
 */
export function refinePartsWithMFcode(parts, mfcode) {
  parts.forEach(p => {
    if (p.oemPartNumber || p.partNumber) {
      p.dtDmsPartCode = p.dtDmsPartCode === "OT" ? p.dtDmsPartCode : mfcode;
    }
  });
}
