/* eslint-disable no-async-promise-executor */
import { makeSecureRestApi } from "../../../api/xmmAxios";
import { filterPaytypesByMake } from "../../page-wrapper/utils/data-util";
import { YES } from "../../repair-order/constants/adjustment.constant";
import vehicleSchema from "../schemas/vehicle.schema";

import isEmpty from "lodash/isEmpty";
const saveVehicle = async ({ customer, vehicle, appConfig }) => {
  const restEndPoint = `/customer/${customer.personId}/schema/${appConfig.schemaName}/vehicle`;

  const formData = vehicleSchema.editAddVehicleRequest.cast(
    {
      ...vehicle,
      webkey: appConfig.webKey,
      dealerCode: appConfig.dealerCode
    },
    {
      stripUnknown: true
    }
  );

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      data => {
        if (!data.vin) {
          data.vin = vehicle.vin;
        }
        resolve(data);
      },
      error => {
        reject(error);
      },
      "Unable to save vehicle."
    );
  });
};

const editVehicle = async ({ customer, vehicle, appConfig }) => {
  const restEndPoint = `/customer/${customer.personId}/schema/${appConfig.schemaName}/vehicle/${vehicle.vehicleId}`;

  const formData = vehicleSchema.editAddVehicleRequest.cast(
    {
      ...vehicle,
      mileageDate: vehicle.mileageDt,
      webkey: appConfig.webKey,
      dealerCode: appConfig.dealerCode
    },
    {
      stripUnknown: true
    }
  );

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "put",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      data => {
        resolve(data);
      },
      error => {
        reject(error);
      },
      "Unable to save vehicle changes."
    );
  });
};

const getMetaVehicleId = async ({ vehicle, appConfig }) => {
  const { dealerCode } = appConfig;
  const restEndPoint = `/vehicle/getMetaVehicleId/${dealerCode}`;
  const {
    make,
    model,
    year,
    trim,
    engineSize,
    engineType,
    driveType,
    transmissionType
  } = vehicle;
  const reqParams = {
    make,
    year,
    model,
    trim,
    engineSize,
    engineType,
    driveType,
    transmissionType
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: reqParams,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      data => {
        resolve(data);
      },
      error => {
        reject(error);
      },
      "Unable to find vehicle."
    );
  });
};

const getVehicleTrims = async ({ vehicle, appConfig }) => {
  const { dealerCode, locale } = appConfig;
  const { make, model, variant, year } = vehicle;

  const restEndPoint = `/vehicle/make/${make}/variant/${variant}/model/${model}/year/${year}/trims/${dealerCode}`;

  const reqParams = {
    locale,
    useSkipTrim: 0
  };

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params: reqParams
      },
      data => {
        resolve(data);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve vehicle trim options."
    );
  });
};

const getAppointments = async ({ customer, vehicle, appConfig }) => {
  const restEndPoint = `/customer/${customer.personId}/schema/${appConfig.schemaName}/vehicle/${vehicle.vehicleId}/${appConfig.dealerCode}/appointments`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "GET"
      },
      data => {
        resolve(data);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve appointments."
    );
  });
};

const getHasFutureAppointments = async ({ customer, vehicle, appConfig }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = {
        hasFutureAppointments: false,
        appointmentDate: null
      };
      let appointments = [];
      if (!isEmpty(customer) && !isEmpty(vehicle)) {
        appointments = await getAppointments({
          customer,
          vehicle,
          appConfig
        });
      }
      if (appointments.length !== 0) {
        for (const appointment of appointments) {
          const appointmentDate = new Date(appointment.appointmentDate);
          if (appointmentDate > new Date()) {
            response.hasFutureAppointments = true;
            response.appointmentDate = appointmentDate;
            break;
          }
        }
      }
      resolve(response);
    } catch (error) {
      reject();
    }
  });
};
// @note: Catalog internal API returns list of paytypes using dealercode, make(supported/unsupported make)
/**
 * Example:
params: make and defaultPayType are optional. dealerCode is required
https://catalogadmin-ua9.xtimeappsnp.xtime.com/opsadmin/partsandlabor/getDealerPayTypes?dealerCode=XTIMEQUOTINGXX1
API will get all dealer paytypes added for all makes(BMW, KIA,NISSAN, ANY etc)

https://catalogadmin-ua9.xtimeappsnp.xtime.com/opsadmin/partsandlabor/getDealerPayTypes?dealerCode=XTIMEQUOTINGXX1&make=BMW
API will get all paytypes for this dealer and supported make(BMW) including ALL MAKES paytypes (ANY make).

https://catalogadmin-ua9.xtimeappsnp.xtime.com/opsadmin/partsandlabor/getDealerPayTypes?dealerCode=XTIMEQUOTINGXX1&make=BMW&defaultPayType=1
API will get paytypes with default paytype(=true) for dealer, supported make(BMW) including ALL MAKES paytypes(ANY make).

https://catalogadmin-ua9.xtimeappsnp.xtime.com/opsadmin/partsandlabor/getDealerPayTypes?dealerCode=XTIMEQUOTINGXX1&make=KIA&defaultPayType=1
API will get paytypes added for ANY make (ALL MAKES) for dealer only, since KIA is unsupported make.
*/
const getPayTypeInformation = async (make, dealerCode) => {
  const endPoint = `/quote/services/${dealerCode}/payTypes`;
  const { SPI_SQS_ENABLED } = JSON.parse(
    sessionStorage.getItem("dealerProperties")
  );
  const requestParams = {
    make
  };
  if (SPI_SQS_ENABLED === YES) {
    requestParams.defaultPayType = 1;
  }
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: endPoint,
        method: "get",
        data: {},
        params: requestParams,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      response => {
        if (SPI_SQS_ENABLED === YES) {
          response.payTypes = filterPaytypesByMake(response?.payTypes, make);
          console.log("filtered cdk paytypes", response);
        }
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve pay type information."
    );
  });
};

// @note: this service will return list of serviceTypes
const getServiceTypeInformation = async (make, dealerCode) => {
  const endPoint = `/quote/services/${dealerCode}/serviceTypes`;
  const requestParams = {
    make
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: endPoint,
        method: "get",
        data: {},
        params: requestParams,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve service type information."
    );
  });
};

const getVendorList = async (make, dealerCode) => {
  const endPoint = `/quote/services/${dealerCode}/vendors`;
  const requestParams = {
    make
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: endPoint,
        method: "get",
        data: {},
        params: requestParams,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve service contract information."
    );
  });
};

export default {
  saveVehicle,
  editVehicle,
  getMetaVehicleId,
  getAppointments,
  getHasFutureAppointments,
  getVehicleTrims,
  getPayTypeInformation,
  getServiceTypeInformation,
  getVendorList
};
